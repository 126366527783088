import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { seo, intro, main } from '../content/branze'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Intro from '../components/Intro'
import Button from '../components/Button'
import { sLinkTiles } from '../style'
import LinkTile from '../components/LinkTile'
import Main from '../components/Main'

const subtitle = {
  pl: 'Branże',
  en: 'Services',
  ua: 'Послуги',
}

const Uslugi = () => {
  const { lang } = useLangContext()
  const { setFormOpen, setFormType } = useFormContext()

  const { image, industries } =
    useStaticQuery(graphql`
      {
        image: file(absolutePath: { regex: "/images/intros/uslugi.jpg/" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: FIXED
              quality: 100
              placeholder: BLURRED
              formats: [JPG]
            )
          }
        }
        industries: allContentfulIndustry {
          nodes {
            title
            lang {
              slug
            }
            slug
            image {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 100
                placeholder: BLURRED
                formats: [JPG]
              )
            }
          }
        }
      }
    `)

  const button = {
    text: {
      pl: 'Przeglądaj branże',
      en: 'Explore services',
      ua: 'ПЕРЕГЛЯНУТИ ПОСЛУГИ',
    },

    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Zapytaj o ofertę',
      en: 'Ask for an offer',
      ua: 'ЗАПИТАЙТЕ ПРОПОЗИЦІЮ',
    },
    action: () => {
      setFormType('MESSAGE')
      setFormOpen(true)
    },
  }

  const buttonBack = {
    text: {
      pl: 'Sprawdź rodzaje transportu',
      en: 'Check out transport types',
      ua: 'Перевірте види транспорту',
    },
    link: '/transport/',
  }

  const links = industries.nodes
    .filter((node) => node.lang.slug === lang)
    .map(({ slug, title, image }) => ({
      slug: `/branze/${slug}/`,
      title,
      image,
      subtitle,
    }))

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
        position='top'
      />
      <Main h={1} title={main.title[lang]} body={main.body[lang]}>
        <div css={sLinkTiles}>
          {links.map((item, id) => (
            <LinkTile key={id} data={item} />
          ))}
        </div>
        {/* <Button link={buttonBack.link}>{buttonBack.text[lang]}</Button> */}
      </Main>
    </Layout>
  )
}

export default Uslugi
